.main-div {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr repeat(3, 1fr) 2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
  height: 100vh;
}

.title {
  width: 20rem;
  grid-row-start: 2;
}

.sub-title {
  font-weight: lighter;
  font-size: large;
  opacity: 0.7;
  padding-bottom: 1rem;
  margin-top: 0;
  grid-row-start: 3;
}

#startButton {
  font-size: 25px;
}

.effect-container {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;

  --effect-name: ripple;
  --effect-duration: 1000ms;
  --effect-top: 0px;
  --effect-left: 0px;
  --effect-height: 100px;
  --effect-width: 100px;
  --effect-color: white;
}

.effect-container::before {
  content: "";
  position: absolute;
  opacity: 0.3;

  top: var(--effect-top);
  left: var(--effect-left);
  height: var(--effect-height);
  width: var(--effect-width);
  border-radius: 50%;
  transform: scale(0);
  background: var(--effect-color);
}

.effect-container.active::before {
  border-radius: 50%;
  animation: ripple var(--effect-duration) linear forwards;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 0.2;
  }
  to {
    transform: scale(6);
    opacity: 0;
  }
}

.back-button {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 1rem;
    padding: 0.5rem 1rem 1rem 0;
    font-size: large;
    /* font-weight: regu; */
    opacity: 0.4;
    border-top: 3px solid white;
  }

.reset-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  padding: 0.5rem 0rem 1rem 1rem;
  font-size: large;
  text-align: right;
  opacity: 0.4;
  border-top: 3px solid white;
}

.home-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.home-next {
  font-size: 15px;
  font-weight: lighter;
}

.nav-div {
  display: block;
  /* justify-content: flex-start; */
  /* align-content: center; */
  margin-top: 30vh;
  width: 15vw;
  height: 45vh;
  opacity: 0.5;
}

.nav-div:hover {
  opacity: 0.8;
}

.nav-button {
  writing-mode: vertical-lr;
  font-size: 35px;
  font-weight: lighter;
  margin: 0;
}

.nav-div img {
  width: 25px;
  padding-bottom: 1rem;
}

.dist-bgd {
  background: linear-gradient(#0e192a65, #1f365b65);
  height: max-content;
}

.dist-title {
  text-transform: uppercase;
  font-weight: bold;
  padding: 3rem 0;
}

.distance-div {
  height: 2490vh;
  max-height: 2490vh;
  /* margin-top: 33vh; */
  position: relative;
  bottom: 0;
}

.dist-planet {
  display: grid;
  grid-template-columns:1fr 2fr 2fr 2fr;
  /* bottom: 60px; */
}

.dist-name {
  margin: auto 0;
  font-size: 1.5rem;
  grid-column-start: 2;
  text-transform: uppercase;
  /* text-align: right; */
  justify-self: end;
}

.dist-icon {
  grid-column-start: 3;
  justify-self: center;
}
.dist-val {
  grid-column-start: 4;
  justify-self: end;
  top: 0;
}

.dist-line {
  width: 50px;
  height: auto;
  background-image: url('../Img/Line.svg');
  background-size: contain;
  /* margin-top: 4vh; */
  justify-content: center;
  max-height: 2490vh;
  overflow: hidden;
}

.dist-pop-up:hover {
  opacity: 0.5
}

.pop-up {
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 80%;
  margin: auto;
  padding: 2rem;
  background-color: #0e192ae7;
  border-radius: 1rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
}

.close-button {
  position: relative;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: max-content;
  font-size: 30px;
  width: 2rem;
}

.close-button:hover {
  opacity: 0.5;
}

.blur-bg {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: blur(5px);
  justify-content: center;
  opacity: 0;
}

.size-div {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 5rem;
  height: 50vh;
  width: 500vw;
  margin-left: -200vw;
}

.size-planets {
  margin: 0 auto;
}

.size-title {
  justify-content: center;
  align-items: flex-start;
  vertical-align: middle; 
  display: flex; 
  flex-direction: row;
}

@media screen and (max-height: 1024px) and (max-width: 768px) {
  .main-div,
  .effect-container {
    /* do something.. */
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
  }
}

@media screen and (max-width: 481px) {
  .nav-button {
    font-size: 20px;
  }

  .distance-div {
    width: 85%;
  }
  
  .dist-val {
    font-size: 12px;
    vertical-align: middle;
    margin: auto 0;
  }

  .dist-name {
    font-size: 1rem;
  }
}