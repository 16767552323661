.App {
  /* display: grid; */
  text-align: center;
  background: linear-gradient(#0e192a, #1f365b);
  width: 100vw;
  height: 100vh;

  color: white;
}

@media screen and (max-height: 1024px) and (max-width: 768px) {

  .App {
    /* do something.. */
    /* height: -moz-available; */
    /* height: -webkit-fill-available; */
    height: fill-available;
  }
}

.layered>* {
  grid-column-start: 1;
  grid-row-start: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:hover {
  opacity: 0.5;
}
